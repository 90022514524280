<template>
    <div></div>
</template>

<script>
import {
    BANKSLIP_PDF_VIEW,
    HOME_VIEW,
    LEGACY_TOKEN_VIEW,
    PLAN_SELECTOR_VIEW,
    MIGRATION_PLAN_VIEW,
} from "@/router/constants";
import { mapActions } from "vuex";
import { CHANGE_TOKEN, LOGIN_MODULE } from "@/store/constants/login";
import { GET_STORE, STORE_MODULE } from "@/store/constants/store";
import { CHANGE_ACCESS_ORIGIN } from "@/store/constants/app"
export default {

    name: LEGACY_TOKEN_VIEW,

    async created() {
        const token = this.$route?.params?.token;
        const redirect = this.$route?.params?.redirect;
        const origin = this.$route?.query?.origin || "web";

        if (!token) {
            return this.$fire({
                text: this.$t("login.no_token"),
                type: "error",
                timer: 6000,
                customClass: "alert-error-1",
            });
        }
        await this.changeTokenAction(token);
        const profile = await this.getProfile();

        this.accessOriginChange(origin)

        if (redirect?.includes("assinar") && profile.payload.subscription?.is_free) {
            return await this.checkoutFlow();
        }

        if (redirect?.includes("migrar")) {
            return await this.migrationFlow();
        }

        if (redirect === "show-payment-slip") {
            return await this.showBankslipFlow();
        }

        await this.miscFlow();
    },

    methods: {
        ...mapActions({
            changeTokenAction: `${LOGIN_MODULE}/${CHANGE_TOKEN}`,
            getProfile: `${STORE_MODULE}/${GET_STORE}`,
            changeAccessOrigin: CHANGE_ACCESS_ORIGIN,
        }),

        accessOriginChange(origin){
            return this.changeAccessOrigin(origin);
        },

        async checkoutFlow() {
            return this.$router.push({ name: PLAN_SELECTOR_VIEW });
        },

        async migrationFlow() {
            return this.$router.push({ name: MIGRATION_PLAN_VIEW });
        },

        async showBankslipFlow() {
            return this.$router.push({ name: BANKSLIP_PDF_VIEW });
        },

        async miscFlow() {
            return this.$router.push({ name: HOME_VIEW });
        },
    },
};
</script>

<style></style>
